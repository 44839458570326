import styled from 'styled-components'
import { FColG16, FRow } from '../Layout'
import { H3 } from '../Text'

export const Wrapper = styled(FRow)`
    padding: 32px;
    background-color: white;
    box-shadow: 0 0 15px 5px rgba(0, 0, 0, 0.1);
`
export const Table = styled.div`
    display: grid;
    width: 100%;
    min-width: 250px;
    grid-auto-rows: 24px;
    grid-template-columns: 1fr auto;
`

export const Separator = styled.div`
    grid-column: 1 / span 2;
    max-height: 1px;
    height: 1px;
    background-color: rgb(238, 238, 238);
    align-self: center;
    transform: translateY(-1px);
`

export const MoneySpan = styled.span`
    justify-self: flex-end;
`

export const MainColumn = styled(FColG16)`
    flex: 1;
    align-items: center;
`

export const Title = styled(H3)`
    font-size: 1.5rem;
    font-weight: 700;
`

export const BoldSpan = styled.span`
    font-weight: 600;
`
