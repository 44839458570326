import styled from 'styled-components'

export const ClientName = styled.div`
    font-weight: 500;
    font-size: 1rem;
    line-height: 1rem;
    color: rgba(28, 33, 31, 0.9);
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 1.375rem;
    line-height: 22px;
    color: rgba(28, 33, 31, 0.9);
`

export const Comment = styled.div`
    text-align: justify;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 18px;
    color: #1c211f;
`
