import React from 'react'
import { BgProps, colorFilters } from '../../atoms/Bg'
import { FRow } from '../../atoms/Layout'
import {
    BgAnimation,
    BgLRAnimation,
    Description,
    FloatBase,
    FloatImageBase,
    HeaderColLeft,
    HeaderColRight,
    LRWrapper,
    RLWrapper,
    SBg,
    SContent,
    SpiderWeeb,
    Title,
} from '../../organisms/Introduction/styled'
import { useRenderBreakpoints } from '../../utils/hooks/render'

export type IntroAnimatedImageProps = {
    title: React.ReactNode
    description?: React.ReactNode
    afterDescription?: React.ReactNode
    bgColor: BgProps['color']
    img: React.ReactNode
    direction?: 'rl' | 'lr'
    centerVertically?: boolean
    beforeTitle?: React.ReactNode
    afterTitle?: React.ReactNode
    float?: React.ReactNode
}

export const IntroAnimatedImage = React.memo<IntroAnimatedImageProps>(
    ({
        title,
        img,
        centerVertically,
        direction = 'rl',
        description,
        afterDescription,
        bgColor,
        beforeTitle,
        afterTitle,
        float,
    }) => {
        const { onTablet } = useRenderBreakpoints()
        const animation = direction !== 'rl' ? BgLRAnimation : BgAnimation
        const HeaderCol = direction === 'rl' ? HeaderColLeft : HeaderColRight
        const ImgWrapper = direction === 'rl' ? RLWrapper : LRWrapper

        const getContent = (a: React.ReactNode, b: React.ReactNode) => {
            return direction !== 'rl' ? (
                <>
                    {b}
                    {a}
                </>
            ) : (
                <>
                    {a}
                    {b}
                </>
            )
        }
        return (
            <FRow>
                <SBg
                    animation={animation}
                    color={bgColor}
                    useHPadding={true}
                    amount={0.7}
                >
                    <SContent variant={'transparent'} useMaxWidth={true} expanded={true}>
                        {getContent(
                            <HeaderCol centerVertically={centerVertically}>
                                {beforeTitle}
                                <Title>{title}</Title>
                                {afterTitle}
                                <Description>{description}</Description>
                                {afterDescription}
                            </HeaderCol>,
                            <FloatBase>
                                <FloatImageBase>
                                    <ImgWrapper>{img}</ImgWrapper>
                                </FloatImageBase>
                                {float}
                                {onTablet(
                                    () => (
                                        <SpiderWeeb
                                            style={{
                                                filter:
                                                    colorFilters[bgColor] +
                                                    ' brightness(0.9)',
                                                ...(direction === 'lr' && {
                                                    right: 0,
                                                    left: 'unset',
                                                }),
                                            }}
                                        />
                                    ),
                                    { mode: 'hide' },
                                )}
                            </FloatBase>,
                        )}
                    </SContent>
                </SBg>
            </FRow>
        )
    },
)

IntroAnimatedImage.displayName = 'IntroAnimatedImage'

export default IntroAnimatedImage
