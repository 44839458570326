import React from 'react'
import image18 from '../../../images/reviews/image 18.jpg'
import ImageWActionRow from '../../../molecules/ImageWActionRow'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import GalleryButton from '../../../molecules/GalleryButton'

export type VisitOurGalleryProps = {}

export const VisitOurGallery = React.memo<VisitOurGalleryProps>(({}) => {
    const { t } = useTranslation(['reviews'])

    return (
        <ImageWActionRow
            color={'blue'}
            image={image18}
            title={[
                t('visit-our-gallery.title.part1'),
                t('visit-our-gallery.title.part2'),
            ]}
            actionCmp={<GalleryButton />}
        />
    )
})

VisitOurGallery.displayName = 'VisitOurGallery'

export default VisitOurGallery
