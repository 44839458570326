import styled from 'styled-components'
import { FCol } from '../../atoms/Layout'
import { sBreakpoints } from '../../constants'
import { Bg, bgFadeGrowLR, bgFadeGrowRL, bgVertical, NormalBg } from '../../atoms/Bg'
import { H2, H3 } from '../../atoms/Text'
import { FadeIn, TranslateLR, TranslateRL } from '../../atoms/Animations'
import Content from '../../atoms/Content'
import spiderweeb from '../../images/spiderweeb.png'
import introductionImg from '../../images/introduction.jpg'

export const RLWrapper = styled.div`
    width: 100%;
    animation: ${TranslateRL}, ${FadeIn};
`
export const LRWrapper = styled.div`
    width: 100%;
    animation: ${TranslateLR}, ${FadeIn};
`

export const BgAnimation = styled(NormalBg)`
    ${sBreakpoints.notTablet} {
        ${bgFadeGrowLR}
    }
    ${sBreakpoints.tablet} {
        ${bgVertical}
    }
`

export const BgLRAnimation = styled(NormalBg)`
    ${sBreakpoints.notTablet} {
        ${bgFadeGrowRL}
    }
    ${sBreakpoints.tablet} {
        ${bgVertical}
    }
`

export const SBg = styled(Bg)`
    position: relative;
    align-items: center;
    padding-bottom: 64px;
    ${sBreakpoints.tablet} {
        padding-bottom: 16px;
    }
`

export const SContent = styled(Content)`
    display: flex;
    flex-direction: row;
    ${sBreakpoints.tablet} {
        justify-content: space-between;
        flex-direction: column;
    }
`

const HeaderColBase = styled(FCol)<{ centerVertically?: boolean }>`
    flex-basis: 50%;
    ${(props) => (props.centerVertically ? 'justify-content: center;' : '')}

    ${sBreakpoints.tablet} {
        flex-basis: 100%;
    }
`

export const HeaderColLeft = styled(HeaderColBase)`
    margin-right: 32px;
    padding-right: 16px;

    ${sBreakpoints.tablet} {
        margin-right: 0;
    }
`
export const HeaderColRight = styled(HeaderColBase)`
    margin-left: 32px;
    padding-left: 16px;

    ${sBreakpoints.tablet} {
        order: -1;
        margin-left: 0;
    }
`

export const Title = styled(H2)`
    margin-top: 64px;

    ${sBreakpoints.tablet} {
        margin-top: 32px;
    }
`

export const TitleSpan = styled.span`
    font-weight: 400;
`

export const Description = styled(H3)`
    width: 70%;
    z-index: 2;
    ${sBreakpoints.tablet} {
        width: 100%;
    }
`

export const FloatBase = styled.div`
    position: relative;
    margin-top: 0;
    z-index: 1;
    ${sBreakpoints.tablet} {
        margin-top: 64px;
    }
`
export const FloatImageBase = styled.div`
    position: relative;
    overflow: hidden;
`

export const SpiderWeeb = styled.img.attrs({
    src: spiderweeb,
})`
    position: absolute;
    left: 0;
    bottom: 0;
    z-index: -1;
    transform: translate(-65%, 45%);
`

export const IntroductionImg = styled.img.attrs({
    src: introductionImg,
})`
    z-index: 2;
    width: 100%;
`
export const EstimateButtonWrapper = styled.div`
    margin-top: 24px;
    z-index: 2;
`
