import styled from 'styled-components'
import introductionImg from '../../../images/testimonies 1.jpg'
import { sBreakpoints } from '../../../constants'

export const IntroductionImg = styled.img.attrs({
    src: introductionImg,
})`
    z-index: 2;
    width: 100%;
`

export const QuoteWrapper = styled.div`
    position: absolute;
    left: -56px;
    bottom: 0;
    transform: translateY(50%);

    ${sBreakpoints.tablet} {
        left: 0;
        transform: translateY(70%);
    }
`
