import React from 'react'
import SectionTitle from '../../molecules/SectionTitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Content from '../../atoms/Content'
import { Bg } from '../../atoms/Bg'
import { Fade } from '@material-ui/core'
import { ButtonBlack, ButtonWArrow } from '../../atoms/Buttons/Button'
import * as S from './styled'
import FrameLogo from '../../images/articles/frame.svg'
import DomusLogo from '../../images/articles/domus.svg'
import Grid from '@material-ui/core/Grid'
import ThinArrow from '../../atoms/Icons/ThinArrow'

export type NewsProps = {}

const articles = [
    {
        logo: FrameLogo,
        comment:
            'ATELIER CARACAS EXPLORES FORMS OF INCLUSIVE ARCHITECTURE WITH A CENTRE FOR NEURODIVERSE CHILDREN',
        link: 'https://www.frameweb.com/article/fun-maze-atelier-caracas',
    },
    {
        logo: DomusLogo,
        comment:
            'Atelier Caracas designs a colourful labyrinth for children’s physical rehabilitation'.toUpperCase(),
        link: 'https://www.domusweb.it/en/architecture/gallery/2020/12/14/atelier-caracas-designs-colourful-labyrinth-for-childrens-physical-rehabilitation.html',
    },
]

export const News = React.memo<NewsProps>(({}) => {
    const { t } = useTranslation(['reviews'])

    const [selectedArticle, setSelectedArticle] = React.useState(0)

    return (
        <Bg
            color={'white'}
            useHPadding={true}
            style={{ alignItems: 'center', marginTop: 80 }}
        >
            <Content variant={'transparent'} useMaxWidth={true} expanded={true}>
                <SectionTitle
                    title={t('in-the-news.title')}
                    description={t('in-the-news.description')}
                />
                <S.NewsWrapper>
                    <Grid
                        container
                        spacing={3}
                        direction={'column'}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <Grid
                                container
                                spacing={2}
                                justify={'center'}
                                wrap={'nowrap'}
                            >
                                {articles.map((article, idx) => {
                                    const Logo = article.logo
                                    return (
                                        <Grid key={idx} item>
                                            <S.LogoWrapper
                                                selected={idx === selectedArticle}
                                                onClick={() => setSelectedArticle(idx)}
                                            >
                                                <Logo />
                                            </S.LogoWrapper>
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                        <Grid item>
                            <Fade key={selectedArticle} in={true}>
                                <Grid
                                    container
                                    spacing={3}
                                    direction={'column'}
                                    alignItems={'center'}
                                    style={{
                                        maxWidth: 600,
                                    }}
                                >
                                    <Grid item>
                                        <S.Comment>
                                            {`"${articles[selectedArticle]?.comment}"`}
                                        </S.Comment>
                                    </Grid>
                                    <Grid item>
                                        <ButtonWArrow
                                            as={ButtonBlack}
                                            onClick={() => {
                                                window.open(
                                                    articles[selectedArticle]?.link,
                                                    '_blank',
                                                    'noopener,noreferrer',
                                                )
                                            }}
                                        >
                                            {t('in-the-news.goto-article')}
                                            <ThinArrow />
                                        </ButtonWArrow>
                                    </Grid>
                                </Grid>
                            </Fade>
                        </Grid>
                    </Grid>
                </S.NewsWrapper>
            </Content>
        </Bg>
    )
})

News.displayName = 'News'

export default News
