import styled from 'styled-components'
import { sBreakpoints } from '../../constants'

export const Content = styled.div`
    flex: 1;
    ${sBreakpoints.tablet} {
        padding-bottom: 80px;
        padding-left: var(--content-padding);
        padding-right: var(--content-padding);
    }
`
