import React from 'react'
import ImageWActionRow from '../../../molecules/ImageWActionRow'
import EstimateButton from '../../EstimateButton'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import testBath from '../../../images/reviews/test-bath 1.jpg'

export type RenewIsHardProps = {}

export const RenewIsHard = React.memo<RenewIsHardProps>(({}) => {
    const { t } = useTranslation('index')

    return (
        <ImageWActionRow
            color={'flesh'}
            image={testBath}
            title={[t('countWithUs.title.part1'), t('countWithUs.title.part2')]}
            actionCmp={<EstimateButton />}
        />
    )
})

RenewIsHard.displayName = 'RenewIsHard'

export default RenewIsHard
