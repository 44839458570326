import React from 'react'
import Grid from '@material-ui/core/Grid'
import Chip from '@material-ui/core/Chip'
import Stars from '../../atoms/Stars'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'
import * as S from './styled'
import { FRowG5 } from '../../atoms/Layout'

export type Category = {
    name: string
}

export type GalleryImage = {
    src: string
    imageName: string
    projectName: string
    categories: Category[]
}

export type ClientReviewType = {
    comment: string
    title: string
    clientFullName: string
    categories: Category[]
    gallery: GalleryImage[]
    rate: number
}

export type ClientReviewProps = {
    review: ClientReviewType
}

export const ClientReview = React.memo<ClientReviewProps>(({ review }) => {
    return (
        <Grid container direction={'column'} spacing={4}>
            <Grid item>
                <Grid
                    container
                    justify={'space-between'}
                    spacing={2}
                    alignItems={'center'}
                    wrap={'nowrap'}
                >
                    <Grid item>
                        <Grid container direction={'column'} spacing={1}>
                            <Grid item>
                                <Grid container spacing={1} alignItems={'center'}>
                                    <Grid item>
                                        <S.ClientName>
                                            {review.clientFullName}
                                        </S.ClientName>
                                    </Grid>
                                    <Grid item>
                                        <Stars
                                            layout={FRowG5}
                                            rate={review.rate}
                                            size={15}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <S.Title>{review.title}</S.Title>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item>
                        <VerifiedUserIcon style={{ color: '#0AC275' }} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <S.Comment>{`"${review.comment}"`}</S.Comment>
            </Grid>
            <Grid item>
                <Grid container spacing={2}>
                    {review.categories.map((category, i) => {
                        return (
                            <Grid item key={i}>
                                <Chip label={category.name} />
                            </Grid>
                        )
                    })}
                </Grid>
            </Grid>
            <Grid item>
                <Grid container spacing={1} alignItems={'center'}>
                    <Grid item>
                        {review.gallery.map((image, i) => {
                            return (
                                <div
                                    key={i}
                                    style={{ maxHeight: 64, backgroundColor: '#C4C4C4' }}
                                >
                                    <img src={image.src} />
                                </div>
                            )
                        })}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
})

ClientReview.displayName = 'ClientReview'

export default ClientReview
