import React from 'react'
import IntroAnimatedImage from '../../../molecules/IntroAnimatedImage'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import { Title } from '../../Introduction/styled'
import { useRenderBreakpoints } from '../../../utils/hooks/render'
import { SpaceV100 } from '../../HowItWorks/styled'
import { IntroductionImg, QuoteWrapper } from './styled'
import QuoteCard from '../../../molecules/QuoteCard'
import { range } from '../../../utils'

export type IntroductionProps = {}

export const Introduction = React.memo<IntroductionProps>(({}) => {
    const { t } = useTranslation(['reviews'])
    const { onTablet } = useRenderBreakpoints()

    return (
        <IntroAnimatedImage
            bgColor={'flesh'}
            title={t('title.part1')}
            afterTitle={
                <Title style={{ textDecoration: 'underline', marginTop: 0 }}>
                    {t('title.part2')}
                </Title>
            }
            description={t('description')}
            img={
                <React.Fragment>
                    <IntroductionImg />
                </React.Fragment>
            }
            float={
                <QuoteWrapper>
                    <QuoteCard
                        quotes={range(3).map((x) => ({
                            cite:
                                x +
                                ' No podría estar más contenta. Ahora es mi habitación favorita de la casa!',
                            author: 'Andrea Gonzalez',
                        }))}
                    />
                </QuoteWrapper>
            }
            beforeTitle={onTablet(
                () => (
                    <SpaceV100 />
                ),
                { mode: 'hide' },
            )}
        />
    )
})

Introduction.displayName = 'Introduction'

export default Introduction
