import React from 'react'
import { Bg } from '../../atoms/Bg'
import Content from '../../atoms/Content'
import SectionTitle from '../../molecules/SectionTitle'
import { useTranslation } from 'gatsby-plugin-react-i18next'
import Divider from '../../atoms/Divider'
import ClientReview from '../../molecules/ClientReview'

export type OurClientsProps = {
    noTitle?: boolean
    reviews: ClientReview[]
}

export const OurClients = React.memo<OurClientsProps>(({ noTitle, reviews }) => {
    const { t } = useTranslation(['reviews'])

    return (
        <Bg
            color={'white'}
            useHPadding={true}
            style={{ alignItems: 'center', marginTop: 80 }}
        >
            <Content variant={'transparent'} useMaxWidth={true} expanded={true}>
                {!noTitle && <SectionTitle title={t('our-clients.title')} />}
                {reviews.map((review, i) => {
                    return (
                        <React.Fragment key={i}>
                            {!(i === 0 && noTitle) && (
                                <Divider style={{ marginTop: 32, marginBottom: 32 }} />
                            )}
                            <ClientReview review={review} />
                        </React.Fragment>
                    )
                })}
            </Content>
        </Bg>
    )
})

OurClients.displayName = 'OurClients'

export default OurClients
