import React from 'react'
import { graphql } from 'gatsby'
import Header from '../../molecules/Header'
import Footer from '../../organisms/Footer'
import Introduction from '../../organisms/Testimonies/Introduction'
import News from '../../organisms/News'
import OurClients from '../../organisms/OurClients'
import RenewIsHard from '../../organisms/Testimonies/RenewIsHard'
import VisitOurGallery from '../../organisms/Testimonies/VisitOurGallery'
import { ClientReviewType } from '../../molecules/ClientReview'

export const clientReviews: ClientReviewType[] = Array(2).fill({
    clientFullName: 'Sara C.',
    rate: 5,
    title: 'Conamer se encargó de todo!',
    comment:
        'Conamer se encargó de nuestro tocador (medio baño) que requería una actualización. La experiencia fue estupenda al saber que se encargaron del presupuesto, del diseño (seguimos modificando y nos ayudaron con nuestras muchas preguntas a lo largo del camino), de encontrar al contratista y de asegurar la entrega de todos los suministros. Fue muy fácil trabajar con una sola empresa para todas las partes de nuestra renovación.',
    categories: [{ name: 'Caracas' }, { name: 'Renovación de baño' }],
    gallery: Array(5).fill({
        src: '',
    }),
} as ClientReviewType)
export type ReviewsProps = {}

export const Reviews = React.memo<ReviewsProps>(({}) => {
    return (
        <main>
            <Header />
            <Introduction />
            <News />
            <OurClients reviews={clientReviews} />
            <RenewIsHard />
            <OurClients reviews={clientReviews} noTitle />
            <VisitOurGallery />
            <OurClients reviews={clientReviews} noTitle />
            <Footer />
        </main>
    )
})

Reviews.displayName = 'Reviews'

export default Reviews

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
    }
`
