import { Bg } from '../../atoms/Bg'
import styled from 'styled-components'
import { sBreakpoints } from '../../constants'
import EstimateTableSample from '../../atoms/EstimateTableSample'
import { FColG16, FRowG16 } from '../../atoms/Layout'
import { H2 } from '../../atoms/Text'
import SunLightWeeb from '../../images/sunlightweeb.svg'
import { columnGap, rowGap } from '../../utils'
import Content from '../../atoms/Content'

export const SBg = styled(Bg)`
    margin-top: 128px;
    align-items: center;
`

export const SpaceV100 = styled.div`
    margin-top: 100px;
`

export const SpaceV128T64 = styled.div`
    margin-top: 128px;
    ${sBreakpoints.tablet} {
        margin-top: 64px;
    }
`

export const Step1 = {
    AfterDescription: styled.div`
        padding-top: 16px;
    `,
    Content: styled.div`
        position: relative;
        margin-top: 64px;
        ${sBreakpoints.tablet} {
            margin-top: 32px;
        }
    `,
}

export const Step2 = {
    Content: styled.div`
        padding-top: 16px;
    `,
    RelativeBase: styled(FRowG16)`
        position: relative;
    `,
    SunLightWeeb: styled(SunLightWeeb)`
        position: absolute;
        left: 0;
        bottom: 0;
        transform: translateX(-50%);
    `,
    GirlWithTabletWrapper: styled(FColG16)`
        flex: 0.6;
        z-index: 1;
    `,
    TwoImagesWrapper: styled(FColG16)`
        flex: 0.4;
        ${sBreakpoints.tablet} {
            flex: 1;
        }
    `,
}

export const Step3 = {
    Content: styled.div`
        padding: 16px 64px 0 64px;
        ${sBreakpoints.tablet} {
            padding: 0;
        }
    `,
}

export const ReadyToStart = styled(H2)`
    text-align: center;
    width: 100%;
    margin-bottom: 64px;
`

export const ScheduleACallWrapper = styled.div`
    margin-top: 32px;
`

export const SEstimateTableSample = styled(EstimateTableSample)`
    position: absolute;
    right: 0;
    top: 0;
    transform: translate(-80px, -60%);
`
export const StepTitleWrapper = styled(FRowG16)`
    ${sBreakpoints.tablet} {
        flex-direction: column;
        ${columnGap(0)}
        ${rowGap(16)}
        width: 100% !important;
    }
`

export const StepContent = styled.div`
    padding-top: 16px;
`
export const SContent = styled(Content)`
    padding-left: 64px;
    padding-right: 64px;
    ${sBreakpoints.tablet} {
        padding-left: 0;
        padding-right: 0;
    }
`
