import Grid from '@material-ui/core/Grid'
import React from 'react'
import * as S from './styled'
import { Fade } from '@material-ui/core'
import QuotesSvg from '../../images/quotes.svg'
export type Quote = {
    cite: string
    author: string
}

export type QuoteCardProps = {
    quotes: Quote[]
    interval?: number
}

export const QuoteCard = React.memo<QuoteCardProps>(({ quotes, interval = 2000 }) => {
    const [selected, setSelected] = React.useState(0)
    const qRef = React.useRef(quotes)
    const [stopped, setStopped] = React.useState(false)
    qRef.current = quotes

    React.useLayoutEffect(() => {
        if (stopped) return
        const t = setInterval(() => {
            setSelected((p) => (p + 1) % qRef.current.length)
        }, interval)
        return () => {
            clearInterval(t)
        }
    }, [interval, selected, stopped])

    if (quotes.length === 0) return null
    return (
        <S.Wrapper
            onPointerEnter={() => {
                setStopped(true)
            }}
            onPointerLeave={() => {
                setStopped(false)
            }}
        >
            <QuotesSvg
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 32,
                    transform: 'translateY(-40%)',
                }}
            />
            <Fade key={selected} in={true} timeout={800}>
                <Grid container direction={'column'} spacing={4}>
                    <Grid item>{quotes[selected]?.cite}</Grid>
                    <Grid container item justify={'space-between'}>
                        <Grid item>{quotes[selected]?.author}.</Grid>
                        <Grid item>
                            <Grid container spacing={1}>
                                {quotes.map((_, i) => {
                                    return (
                                        <Grid item key={i}>
                                            <S.Dot
                                                selected={selected === i}
                                                onClick={() => {
                                                    setSelected(i)
                                                }}
                                            />
                                        </Grid>
                                    )
                                })}
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Fade>
        </S.Wrapper>
    )
})

QuoteCard.displayName = 'QuoteCard'

export default QuoteCard
