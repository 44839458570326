import Grid from '@material-ui/core/Grid'
import React from 'react'
import { Bg, BgProps } from '../../atoms/Bg'
import Content from '../../atoms/Content'
import { FRow } from '../../atoms/Layout'
import SectionTitle from '../SectionTitle'
import { ButtonBlack, ButtonWArrow } from '../../atoms/Buttons/Button'
import ThinArrow from '../../atoms/Icons/ThinArrow'
import { useRenderBreakpoints } from '../../utils/hooks/render'
import * as S from './styled'

export type ImageWActionRowProps = {
    image: string
    title: string | string[]
    action?: { text: string; onClick: () => void }
    actionCmp?: React.ReactNode
} & BgProps

export const ImageWActionRow = React.memo<ImageWActionRowProps>(
    ({ image, title, action, actionCmp, ...bgProps }) => {
        const { onTablet } = useRenderBreakpoints()
        return (
            <Bg {...bgProps}>
                <Content useMaxWidth={true} variant={'transparent'} expanded={true}>
                    <FRow>
                        {onTablet(
                            () => (
                                <div style={{ flexBasis: '40%' }}>
                                    <img src={image} style={{ display: 'block' }} />
                                </div>
                            ),
                            {
                                mode: 'hide',
                            },
                        )}
                        <S.Content>
                            <Grid
                                container
                                direction={'column'}
                                spacing={4}
                                alignItems={'center'}
                            >
                                <Grid item>
                                    <SectionTitle title={title} />
                                </Grid>
                                <Grid item>
                                    {actionCmp ? (
                                        actionCmp
                                    ) : (
                                        <ButtonWArrow
                                            as={ButtonBlack}
                                            onClick={action?.onClick}
                                        >
                                            {action?.text}
                                            <ThinArrow />
                                        </ButtonWArrow>
                                    )}
                                </Grid>
                            </Grid>
                        </S.Content>
                    </FRow>
                </Content>
            </Bg>
        )
    },
)

ImageWActionRow.displayName = 'ImageWActionRow'

export default ImageWActionRow
