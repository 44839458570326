import React from 'react'
import { ButtonOrange, ButtonWArrow } from '../../atoms/Buttons/Button'
import ThinArrow from '../../atoms/Icons/ThinArrow'
import { Link, useTranslation } from 'gatsby-plugin-react-i18next'
import { GALLERY } from '../../urls'

export type GalleryButtonProps = {
    size?: React.ComponentProps<typeof ButtonWArrow>['size']
} & Omit<React.ComponentProps<typeof Link>, 'to'>

export const GalleryButton = React.memo<GalleryButtonProps>(
    ({ size = 'large', ...props }) => {
        const { t } = useTranslation('common')
        return (
            <Link {...props} to={GALLERY}>
                <ButtonWArrow as={ButtonOrange} size={size}>
                    {t('common:viewGallery')}
                    <ThinArrow />
                </ButtonWArrow>
            </Link>
        )
    },
)

GalleryButton.displayName = 'GalleryButton'

export default GalleryButton
