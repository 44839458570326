import React from 'react'
import Logo from '../Icons/Logo'
import * as S from './styled'

export type EstimateTableSampleProps = {} & React.HTMLAttributes<HTMLDivElement>

export const EstimateTableSample: React.FC<EstimateTableSampleProps> = React.memo(
    (props) => {
        const separator = () => {
            return <S.Separator />
        }

        const money = (amount: number, styles?: React.CSSProperties) => {
            return (
                <S.MoneySpan style={{ ...styles }}>
                    {amount.toLocaleString('en-US', {
                        style: 'currency',
                        currency: 'USD',
                    })}
                </S.MoneySpan>
            )
        }

        return (
            <S.Wrapper {...props}>
                <S.MainColumn>
                    <S.Title>Tu estimado</S.Title>
                    <S.Table>
                        <span>Diseño</span>
                        {money(3000)}
                        {separator()}
                        <span>Gerencia de proyecto</span>
                        {money(2000)}
                        {separator()}
                        <span>Construcción</span>
                        {money(15000)}
                        {separator()}
                        <S.BoldSpan>Total estimado</S.BoldSpan>
                        {money(20000, { fontWeight: 600 })}
                        <span />
                        <span />
                        <span />
                        <span />
                    </S.Table>
                    <Logo />
                </S.MainColumn>
            </S.Wrapper>
        )
    },
)

EstimateTableSample.displayName = 'EstimateTableSample'

export default EstimateTableSample
