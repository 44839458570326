import styled from 'styled-components'

export const Wrapper = styled.div`
    padding: 32px;
    position: relative;
    background: #ffffff;
    box-shadow: 0 4px 20px 5px rgba(0, 0, 0, 0.25);
    font-weight: 500;
    font-size: 16px;
    line-height: 16px;
`

export const Dot = styled.div<{ selected?: boolean }>`
    cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
    background: ${(props) => (props.selected ? '#2E2E2E' : '#C4C4C4')};
    font-size: 10px;
    width: 1em;
    height: 1em;
`
