import styled from 'styled-components'
import { sBreakpoints } from '../../constants'

export const NewsWrapper = styled.div`
    border: 2px dashed #666666;
    margin-top: 64px;
    padding: 64px;
    ${sBreakpoints.tablet} {
        padding: var(--content-padding);
    }
`

export const Comment = styled.div`
    font-style: normal;
    font-weight: normal;
    font-size: 1.75rem;
    line-height: 35px;
    text-align: center;
    color: #000000;
    ${sBreakpoints.tablet} {
        font-size: 1.125rem;
        line-height: 23px;
    }
`

export const LogoWrapper = styled.div<{ selected?: boolean }>`
    cursor: ${(props) => (props.selected ? 'default' : 'pointer')};
    transition: opacity 400ms ease-in-out;
    opacity: ${(props) => (props.selected ? '1' : '0.4')};
    max-width: 128px;
    :hover {
        opacity: 1;
    }
`
