import React from 'react'
import { H2, H3 } from '../../atoms/Text'
import { FCol } from '../../atoms/Layout'

export type SectionTitleProps = {
    title: string | string[]
    description?: string | string[]
}

export const SectionTitle: React.FC<SectionTitleProps> = React.memo(
    ({ title, description }) => {
        const titleArr = Array.isArray(title) ? title : [title]
        const descriptionArr = Array.isArray(description) ? description : [description]
        return (
            <FCol style={{ margin: 'auto', alignItems: 'center' }}>
                <H2 style={{ textAlign: 'center', margin: 'auto', marginTop: 64 }}>
                    {titleArr.map((d, i) => (
                        <React.Fragment key={i}>
                            {!!i && <br />}
                            {d}
                        </React.Fragment>
                    ))}
                </H2>
                {description && (
                    <H3
                        style={{
                            maxWidth: 500,
                            textAlign: 'center',
                            margin: 'auto',
                            marginTop: 16,
                            color: '#2E2E2E',
                        }}
                    >
                        {descriptionArr.map((d, i) => (
                            <React.Fragment key={i}>
                                {!!i && <br />}
                                {d}
                            </React.Fragment>
                        ))}
                    </H3>
                )}
            </FCol>
        )
    },
)

SectionTitle.displayName = 'SectionTitle'

export default SectionTitle
